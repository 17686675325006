<template>
  <div class="Notification">
    <div class="list-header">
      <div class="header-left">
        <div class="form">
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.BText8N") }}</div>
            <el-select
              class="date-card-select"
              v-model="searchForm.notificationType"
              :placeholder="$t('lang.BText80')"
              style="width: 200px"
            >
              <el-option
                v-for="item in selectList[$i18n.locale]"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.NText10") }}</div>
            <el-input v-model="searchForm.subject"></el-input>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.NText9") }}</div>
            <!-- <el-date-picker v-model="searchForm.createDate" type="date" value-format="yyyy-MM-dd hh:mm:ss">
            </el-date-picker> -->
            <el-date-picker
              v-model="searchForm.createDate"
              type="datetimerange"
              :range-separator="$t('lang.to')"
              value-format="yyyy-MM-dd HH:mm:ss"
              :start-placeholder="$t('lang.NText300')"
              :end-placeholder="$t('lang.NText209')"
            >
            </el-date-picker>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{ $t("lang.NText8") }}</div>
            <el-input v-model="searchForm.sendTo"></el-input>
          </div>
          <div class="search-item">
            <div class="radio">
              <el-radio-group
                v-model="searchForm.notificationStatus"
                @change="changeRadio"
              >
                <el-radio label="">{{ $t("lang.NText7") }}</el-radio>
                <el-radio label="1">{{ $t("lang.NText6") }}</el-radio>
                <el-radio label="0">{{ $t("lang.NText5") }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="search-button">
          <div class="refresh-button" @click="refresh">
            <svg-icon icon-class="refresh"></svg-icon>
          </div>
          <el-button class="searchbutton" @click="query()">{{
            $t("lang.BText108")
          }}</el-button>
        </div>
      </div>

      <!-- <div class="header-right">
        <el-button @click="clickDraft()">Draft</el-button>
        <el-button @click="clickTemplate()">Template</el-button>
        <el-button class="create" @click="createBooking()"
          >+ Create booking</el-button
        >
      </div> -->
    </div>
    <dwlist
      :url="source.notification_api.listDataIndex"
      :query-params="queryForm"
      ref="notificationList"
      :initDataFlag="false"
      @row-class-name="tableRowClassName"
    >
      <el-table-column prop="type" :label="$t('lang.BText8')" width="80px">
        <template slot-scope="scope">
          <!-- <div class="LinkID">{{ scope.row.Type }}ID:XXXXXXXX</div> -->
          <div v-if="scope.row.notificationType == 'account'" class="qua">
            A
          </div>
          <div
            v-else-if="scope.row.notificationType == 'quotation'"
            class="qua"
          >
            Q
          </div>
          <div v-else-if="scope.row.notificationType == 'booking'" class="qua">
            B
          </div>
          <div v-else-if="scope.row.notificationType == 'shipment'" class="qua">
            S
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="subject" :label="$t('lang.NText10')" width="450px">
        <template slot-scope="scope">
          <div v-if="$i18n.locale == 'en_us'">
            {{ scope.row.subject.message }}
          </div>
          <div v-else>
            <div v-if="scope.row.ruleType == '004'">Link Me账号密码更改成功</div>
            <div v-else-if="scope.row.ruleType == '005'">Link Me账号验证码</div>
            <div v-else-if="scope.row.ruleType == '006'">Link Me账号更改成功</div>
            <div v-else-if="scope.row.ruleType == '007'">Link Me账号多次登录失败</div>
            <div v-else-if="scope.row.ruleType == '008'">{{scope.row.notificationDetails.senderName}}邀请您成为在线合作伙伴</div>
            <div v-else-if="scope.row.ruleType == '010'">邀请成功</div>
            <div v-else-if="scope.row.ruleType == '024'">邀请失败</div>
            <div v-else-if="scope.row.ruleType == '011'">Link Me账号已被激活</div>
            <div v-else-if="scope.row.ruleType == '012'">收到新的报价，报价单{{scope.row.notificationDetails.qId}}</div>
            <div v-else-if="scope.row.ruleType == '013'">报价单[{{scope.row.notificationDetails.qId}}] 已更新</div>
            <div v-else-if="scope.row.ruleType == '014'">报价单[{{scope.row.notificationDetails.qId}}] 即将过期</div>
            <div v-else-if="scope.row.ruleType == '015'">创建新询价[{{scope.row.notificationDetails.qId}}]</div>
            <div v-else-if="scope.row.ruleType == '016'">您的合作伙伴共享了一个询价</div>
            <div v-else-if="scope.row.ruleType == '017'">{{ scope.row.subject.message=='sharing quotation accepted'?'共享的运价被接受':'共享的运价被拒绝' }}</div>
            <div v-else-if="scope.row.ruleType == '018'">订单[{{scope.row.notificationDetails.bookingId}}]审核成功，等待您的出运授权</div>
            <div v-else-if="scope.row.ruleType == '019'">订单[{{scope.row.notificationDetails.bookingId}}]已出运，运单号[{{scope.row.notificationDetails.shipmentId}}]</div>
            <div v-else-if="scope.row.ruleType == '020'">创建新订单 [{{scope.row.notificationDetails.bookingId}}]</div>
            <div v-else-if="scope.row.ruleType == '021'">订单[{{scope.row.notificationDetails.bookingId}}]已报价</div>
            <div v-else-if="scope.row.ruleType == '022'">订单[{{scope.row.notificationDetails.bookingId}}]已被修改</div>
            <div v-else-if="scope.row.ruleType == '023'">订单[{{scope.row.notificationDetails.bookingId}}]已被修改</div>
          </div>
          <!-- <div v-else-if="scope.row.ruleType == '001'">
            <span style="width: 100%; float: left">{{
              scope.row.subject.subjectHead
            }}</span>
            <span style="width: 100%; float: left"
              >{{ scope.row.subject.subjectLeft
              }}<span style="color: #409eff; font-weight: bold"> -> </span
              >{{ scope.row.subject.subjectRight }}</span
            >
          </div> -->
        </template>
      </el-table-column>
      <el-table-column prop="createDate" :label="$t('lang.NText11')">
        <template slot-scope="scope">
          <span v-if="$i18n.locale == 'en_us'">{{
            zhDate_to_ehDate(scope.row.createDate)
          }}</span
          ><span v-else>{{ scope.row.createDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sendTo" :label="$t('lang.NText8')">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="light"
            :content="scope.row.sendTo"
            placement="top"
          >
            <span>{{ scope.row.sendTo }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="sendBy" :label="$t('lang.NText12')">
      </el-table-column>
      <el-table-column fixed="right">
        <template slot-scope="scope">
          <el-button @click="clickView(scope.row)" class="samll-list-info">
            {{ $t("lang.BText18") }}</el-button
          >
        </template>
      </el-table-column>
    </dwlist>
    <el-dialog
      :visible.sync="detailFlag"
      width="70%"
      class="createDialog notificationDialog dw-dialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon
            icon-class="remind"
            class="svg-icon"
            style="color: '#FFF'"
          ></svg-icon>
          {{ $t("lang.BText31") }}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="dialog-main el-dialog-main" v-loading="loading">
        <!-- <div class="dialog-main-title">
          Any quotation notification, you want to notify:
        </div> -->
        <div class="dialog-main-content" :class="{ padding: ruleType == '001' }">
          <!-- <div v-if="ruleType == '001'">
            <span class="mt25">{{ $t("lang.NText4") }}<b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt25">{{ $t("lang.Congratulations") }}</span>
            <span>{{ $t("lang.NText1") }}<b class="col299">{{ list.accountId }}</b></span>
            <span>{{ $t("lang.NText2") }}</span>
            <span class="mt25">{{ $t("lang.NText13") }}</span>
            <el-button class="samll-list-info mt30" @click="clickConfirm()">{{ $t("lang.NText14") }}</el-button>
            <span>{{ $t("lang.NText15") }}</span>
            <a @click="clickConfirm()" class="btn col299"><b class="col299">{{ list.confirmUrl }}</b></a>
            <span class="mt25">{{ $t("lang.NText16") }} </span>
            <span>{{ $t("lang.NText17") }}<b class="col299">{{ list.newEmailUrl }}</b></span>
            <span class="mt25">{{ $t("lang.NText18") }}</span>
            <span class="mt6">{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '002'">
            <span class="mt25">{{ $t("lang.NText4") }}<b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt25">{{ $t("lang.NText3") }}</span>
            <span>{{ $t("lang.NText20") }}<b class="col299">{{ list.accountId }}</b></span>
            <span class="mt25">{{ $t("lang.NText13") }}</span>
            <el-button class="samll-list-info mt30">{{ $t("lang.NText14") }}</el-button>
            <span>{{ $t("lang.NText15") }}</span>
            <a class="btn col299"><b class="col299">{{ list.confirmUrl }}</b></a>
            <span class="mt25">{{ $t("lang.NText16") }}<b class="col299">[24 {{ $t("lang.NText21") }}]</b></span>
            <span>{{ $t("lang.NText17") }}<b class="col299">{{ list.newEmailUrl }}</b></span>
            <span class="mt25">{{ $t("lang.NText18") }}</span>
            <span class="mt6">{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '003'">
            <span class="mt25">{{ $t("lang.NText4") }}<b class="col299">{{ list.receiverName }}</b></span>
            <span> {{ $t("lang.NText23") }}</span>
            <span class="mt25">{{ $t("lang.NText23") }}</span>
            <el-button class="samll-list-info mt30">{{$t("lang.NText24")}}</el-button>
            <span>{{ $t("lang.NText15") }}</span>
            <a class="btn col299"><b class="col299">{{ list.resetUrl }}</b></a>
            <span class="mt25">{{ $t("lang.NText16") }}<b class="col299">[24 {{ $t("lang.NText21") }}]</b></span>
            <span>{{ $t("lang.NText17") }}<b class="col299">{{ list.newEmailUrl }}</b></span>
            <span class="mt25">{{ $t("lang.NText25") }}</span>
            <span class="mt6">{{ $t("lang.NText19") }}</span>
          </div> -->
          <div v-if="ruleType == '004'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt25" v-if="$i18n.locale == 'zh_cn'">你的密码在 <b class="col299">{{ list.dayTime }}</b> <b class="col299">{{ list.hourTime }}</b> 被修改。</span>
            <span class="mt25" v-else>You changed your password at <b class="col299">{{ list.hourTime }}</b> on <b class="col299"> {{ list.dayTime }}</b>.</span>
            <!-- <span>{{ $t("lang.NText27") }}</span> -->
            <span class="mt38">{{ $t("lang.NText28") }}</span>
            <span class="mt6">{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '005'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.loginAccount }}</b></span>
            <span>{{ $t("lang.NText30") }}</span>
            <span class="mt30">{{ $t("lang.NText31") }}</span>
            <span class="code">{{ list.verificationCode }}</span>
            <span class="mt38" v-if="$i18n.locale == 'zh_cn'">校验码将在<b class="col299">[24小时]</b>后过期。</span>
            <span class="mt38" v-else>This code will be expired in <b class="col299">[24 hours].</b></span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '006'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span> {{ $t("lang.NText34") }} </span>
            <span class="mt38">{{ $t("lang.NText35") }} <b class="col299">{{ list.newAccountId }}</b></span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '007'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span>{{ $t("lang.NText37") }} <b class="col299">{{ list.time }}</b></span>
            <span class="mt30"> {{ $t("lang.NText38") }}</span>
            <el-button class="samll-list-info mt30">{{$t("lang.NText24")}}</el-button>
            <span class="mt38">{{ $t("lang.NText39") }}</span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '008'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt30" v-if="$i18n.locale == 'zh_cn'"><b class="col299">{{ list.senderName }}</b> 邀请你成为他在Link Me上的合作伙伴，你的合作角色是 <b class="col299">{{ list.partnerRole }}</b>。</span>
            <span class="mt30" v-else><b class="col299">{{ list.senderName }}</b> invite you to be their Link Me partner, and your role will be as <b class="col299">{{ list.partnerRole }}</b>.</span>
            <el-button @click="clickAccept()" class="samll-list-info">{{ $t("lang.NText49") }}</el-button>
            <el-button @click="clickReject()" class="samll-list-info sec" :disabled="disabledReject">{{$t("lang.NText48")}}</el-button>
            <span class="mt38" v-if="$i18n.locale == 'zh_cn'">请在<b class="col299">[24小时]</b>内完成邀请，否则链接将会失效。</span>
            <span class="mt38" v-else>This link will be expired in <b class="col299">[24 hours].</b></span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <!-- <div v-else-if="ruleType == '009'">
            <span class="mt25"
              >{{$t('lang.NText4')}} <b class="col299">{{ list.receiverName }}</b
              >,</span
            >
            <span class="mt25"
              ><b class="col299">{{ list.senderName }}</b> {{$t('lang.NText47')}}
              <b class="col299">{{ list.partnerRole }}</b></span
            >
            <span class="mt25"
              >{{$t('lang.NText46')}}</span
            >
            <span>Step.1 Sign up on Link Me BMS</span>
            <span class="mt25"><b class="col299">111</b></span>
            <span>Step.2 Click following link to accept.</span>
            <el-button @click="clickAccept()" class="samll-list-info"
              > {{$t('lang.NText49')}}</el-button
            >
            <span
              >{{$t('lang.NText15')}}</span
            >
            <span
              ><b class="col299"
                >[https://XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX]</b
              ></span
            >
            <span class="mt38"
              >This invitation will be expired in
              <b class="col299">[72 {{$t('lang.NText21')}}]</b>.</span
            >
            <span>{{$t('lang.NText19')}}</span>
          </div> -->
          <div v-else-if="ruleType == '010'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span><b class="col299">{{ list.senderName }} </b>{{ $t("lang.NText45")}}</span>
            <!-- <span class="mt38"><b class="col299">{{ list.partnerCompanyName }}</b>{{ $t("lang.NText44") }}<b class="col299">{{ list.partnerRole }}</b></span> -->
            <span v-if="list.enCompanyName">{{ $t("lang.NTextCompanynameen") }} {{ list.enCompanyName }}</span>
            <span v-if="list.cnCompanyName">{{ $t("lang.NTextCompanynamecn") }} {{ list.cnCompanyName }}</span>
            <span>{{ $t("lang.NTextAccount") }} {{ list.accountId }}</span>
            <span class="mt38">{{ $t("lang.NTextRole") }} {{ list.partnerRole }}</span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '024'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt38"><b class="col299">{{ list.senderName }}</b> {{ $t("lang.NText43") }}</span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '011'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt25" v-if="$i18n.locale !== 'zh_cn'">{{ $t("lang.NText42") }}</span>
            <span>{{ $t("lang.NText41") }}</span>
            <span class="mt38">{{ $t("lang.NText51") }}</span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '012'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt20">{{ $t("lang.NText52")}} <b class="col299">{{ list.qId }}</b></span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '013'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt20">{{ $t("lang.NText53") }} <b class="col299">{{ list.qId }}</b> {{ $t("lang.NText56") }}</span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '014'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt38" v-if="$i18n.locale == 'zh_cn'">Quotation ID: <b class="col299">{{ list.qId }}</b> 将在 <b class="col299">{{ list.expiresDay }}</b> 过期。</span>
            <span class="mt38" v-else>Quotation ID: <b class="col299">{{ list.qId }}</b> will be expired on <b class="col299">{{ list.expiresDay }}</b>.</span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '015'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span>{{ $t("lang.NText58") }}</span>
            <span class="mt25">{{ $t("lang.NText53")}} <b class="col299">{{ list.qId }}</b></span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '016'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt20">{{ $t("lang.NText59")}} <b class="col299">{{ list.senderName }}</b> {{ $t("lang.NText50") }}</span>
            <el-button @click="reviewDetail('RP')" class="samll-list-info">Review&process</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '017'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span class="mt38" v-if="$i18n.locale == 'zh_cn'"><b class="col299">{{ list.senderName.indexOf("accepted") > 0?list.senderName.split("accepted")[0]+'接受':list.senderName.split("rejected")[0]+'拒绝'}}</b> 您的共享报价。</span>
            <span class="mt38" v-else><b class="col299">{{ list.senderName}}</b> your sharing quotation.</span>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '018'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span>{{ $t("lang.NText69")}} <b class="col299">{{ list.quotationId }}</b></span>
            <span>{{ $t("lang.NText691")}} <b class="col299">{{ list.bookingId }}</b></span>
            <span class="mt25">{{ $t("lang.NText68") }}</span>
            <el-button @click="clickauthorize()" class="samll-list-info">{{$t("lang.BText12")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '019'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <div v-if="$i18n.locale == 'zh_cn'">
              <span>您的订单:{{ list.bookingId }}，已经正式起航。</span>
              <span class="mt25">运单号:<b class="col299">{{ list.shipmentId }}</b>，将是您本次航程的交易编号。</span>
            </div>
            <div v-else>
              <span>Your booking is shipping.</span>
              <span>Booking ID: <b class="col299">{{ list.bookingId }}</b></span>
              <span class="mt25">Shipment ID: <b class="col299">{{ list.shipmentId }}</b></span>
            </div>
            <span class="mt38">{{ $t("lang.NText671") }}</span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>

          <div v-else-if="ruleType == '020'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span>{{ $t("lang.NText64") }}</span>
            <span class="mt25">{{ $t("lang.NText66") }} <b class="col299">{{ list.bookingId }}</b></span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '021'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span>{{ $t("lang.NText63") }} <b class="col299">{{ list.bookingId }}</b></span>
            <span>{{ $t("lang.NText54")}} <b class="col299">{{ list.quotationId }}</b></span>
            <span class="mt25">{{ $t("lang.NText681") }}</span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '022'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b></span>
            <span>{{ $t("lang.NText63") }} <b class="col299">{{ list.bookingId }}</b></span>
            <span>{{ $t("lang.NText54")}} <b class="col299">{{ list.quotationId }}</b></span>
            <span class="mt25">{{ $t("lang.NText541") }}</span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
          <div v-else-if="ruleType == '023'">
            <span class="mt25">{{ $t("lang.NText4") }} <b class="col299">{{ list.receiverName }}</b>,</span>
            <span>{{ $t("lang.NText63") }} <b class="col299">{{ list.bookingId }}</b></span>
            <span>{{ $t("lang.NText54")}} <b class="col299">{{ list.quotationId }}</b></span>
            <span class="mt25">{{ $t("lang.NText61") }}</span>
            <el-button @click="reviewDetail()" class="samll-list-info">{{$t("lang.QText76")}}</el-button>
            <span>{{ $t("lang.NText19") }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="reviewFlag"
      class="createDialog dw-dialog"
      width="70%"
      @close="closeDialog1"
      id="bookingDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ $t("lang.BText110") }}</div>
          <div
            class="row2"
            v-if="
              bookingViewData.quotation && bookingViewData.quotation.quotationNo
            "
          >
            {{ $t("lang.NText70") }} {{ bookingViewData.quotation.quotationNo }}
          </div>
        </div>
        <div class="titleDate">
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText71") }}</div>
            <div class="date">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(bookingViewData.updateDate)
              }}</span
              ><span v-else>{{ bookingViewData.updateDate }}</span>
            </div>
          </div>
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText72") }}</div>
            <div class="date2">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(bookingViewData.expiredDate)
              }}</span
              ><span v-else>{{ bookingViewData.expiredDate }}</span>
            </div>
          </div>
        </div>
        <div class="close" @click="openCheckBox()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <booking-dialog
          :bookingView="bookingViewData"
          :toSendMsgObj="toSendMsgObj"
          :type="'3'"
        ></booking-dialog>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showReview"
      :visible.sync="showReview"
      class="reviewDialog dw-dialog"
      width="70%"
      @close="showReview = false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ $t("lang.NText73") }}</div>
          <div class="row2">
            {{ $t("lang.NText74")
            }}<span style="color: #fff"> {{ thisRow.quotationNo }}</span>
          </div>
        </div>
        <div class="titleDate" v-if="showReview">
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText75") }}</div>
            <div class="date">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(thisRow.updateDate)
              }}</span
              ><span v-else>{{ thisRow.updateDate }}</span>
            </div>
          </div>
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText72") }}</div>
            <div class="date2">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(thisRow.expiredDate)
              }}</span
              ><span v-else>{{ thisRow.expiredDate }}</span>
            </div>
          </div>
        </div>
        <div class="close" @click="showReview = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <quotation-dialog
          :quotationData="quotationData"
          :stateView="stateView"
          :reviewUrl="list.reviewUrl"
          :acceptUrl="list.acceptLinkage"
          :rejectUrl="list.rejectLinkage"
        ></quotation-dialog>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="acceptReview"
      width="70%"
      class="createDialog notificationDialog dw-dialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon
            icon-class="userHead"
            class="svg-icon"
            style="color: '#FFF'"
          ></svg-icon>
          {{ $t("lang.NText327") }}
        </div>
        <div class="close" @click="acceptReview=false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="editInfo">
        <p class="editInfoTitle" v-if="$i18n.locale == 'zh_cn'">接受来自 <span>{{list.senderName}}</span> 的邀请</p>
        <p class="editInfoTitle" v-else>Accept the partner invitation from <span>{{list.senderName}}</span></p>
        <p class="firstLine" v-if="$i18n.locale == 'zh_cn'">同时, <span>{{list.companyName}}</span> 也将成为您的在线合作伙伴.</p>
        <p class="firstLine" v-else>At the same time, <span>{{list.companyName}}</span> will also become your online partner.</p>
        <el-form class="form" ref="partnerForm" :rules="rules" :model="form">
          <el-form-item
            class="form-item"
            prop="partnerName"
            :label="$t('lang.NText328')"
            :rules="[
              {
                required: true,
                message: $t('lang.NText116'),
                trigger: 'change',
              },
            ]"
          >
            <el-input v-model="form.partnerName"></el-input>
          </el-form-item>
          <el-form-item
            class="form-item"
            prop="senderUserAccount"
            :label="$t('lang.NText329')"
            :rules="[
              {
                required: true,
                message: $t('lang.SText190'),
                trigger: 'change',
              },
            ]"
          >
            <el-input v-model="form.senderUserAccount" readonly=""></el-input>
          </el-form-item>
          <el-form-item
            class="form-item"
            prop="role"
            :label="$t('lang.NText330')"
            :rules="[
              {
                required: true,
                message: $t('lang.NText118'),
                trigger: 'change',
              },
            ]"
          >
            <el-checkbox-group v-model="form.role" style="display: flex;">
              <el-checkbox label="Shipper"></el-checkbox>
              <el-checkbox label="Consignee"></el-checkbox>
              <el-checkbox label="Others"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div class="bottom-button">
            <el-button
              type="primary"
              :loading="formloadSave"
              :disabled="disabledAccept"
              @click="acceptSave('partnerForm')"
              >{{ $t("lang.QText80") }}</el-button
            >
            <el-button type="info" @click="acceptCancel">{{
              $t("lang.NText331")
            }}</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import test from "../../test/test";
import dwSteps from "../../components/dwSteps/dwSteps.vue";
// import createBookingIndex from "../Booking/create-index.vue";
import reviewBooking from "../Booking/review.vue";
// import bookingEdit from "../Booking/edit.vue";
import FillInList from "../Booking/fillInList.vue";
import QuotationDialog from "../Quotation/quotationDialog/quotationDialog.vue";
import utils from "../../utils/util";
import BookingDialog from "../Booking/bookingDialog.vue";
export default {
  components: {
    dwlist,
    dwSteps,
    // createBookingIndex,
    reviewBooking,
    // bookingEdit,
    FillInList,
    QuotationDialog,
    BookingDialog,
  },
  data() {
    return {
      formloadSave:false,
      acceptReview:false,
      disabledAccept:false,
      disabledReject:false,
      disabledRP:false,
      QForm: {},
      bookingViewData: {},
      toSendMsgObj: {},
      queryForm: {},
      quotationTypeObj: {},
      quotationViewData: {},
      reviewFlag: false,
      loading: false,
      listData1: [
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
      ],
      utils: utils,
      listData: [],
      quotationList: test.QuotationData,
      showReview: false,
      searchForm: {
        notificationType: "",
        subject: "",
        sendTo: "",
        notificationStatus: "",
        createDate: "",
      },
      selectList: {
        en_us:[
          {
            name: "booking",
            id: "booking",
          },
          {
            name: "account",
            id: "account",
          },
          {
            name: "shipment",
            id: "shipment",
          },
          {
            name: "quotation",
            id: "quotation",
          }
        ],
        zh_cn:[
          {
            name: "订舱",
            id: "booking",
          },
          {
            name: "账号",
            id: "account",
          },
          {
            name: "出运",
            id: "shipment",
          },
          {
            name: "报价单",
            id: "quotation",
          }
        ],
      },
      openSearch: false,
      detailFlag: false,
      type: "1",
      dialogTitle: "",
      review: false,
      edit: false,
      bookingId: null,
      showFillIn: false,
      stepsData: [],
      list: {},
      ruleType: "",
      num: "",
      thisRow: {},
      notificationType: "",
      quotationData: {},
      stateView: "",
      form:{
        role:[],
        partnerName:'',
        senderUserAccount:''
      },
      rules: {},
    };
  },
  created() {
    if (this.$route.params.editFlag) {
      this.clickEdit();
    }
    if (this.$route.params.detailFlag) {
      this.createBooking();
    }
    if (this.$route.params.notificationStatus) {
      this.searchForm.notificationStatus =
        this.$route.params.notificationStatus;
    }
  },
  mounted() {
    this.query();
  },
  methods: {
    acceptSave(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disabledAccept=true;
          this.formloadSave = true;
          let url = this.list.acceptUrl.split("com")[1];
          let role = this.form.role.toString();
          let Url = url +'&role=' + role +'&partnerName=' + this.form.partnerName +'&senderUserAccount=' + this.list.senderUserAccount;
          this.$ajax.cGet(Url, {}).then((res) => {
            this.formloadSave = false;
            this.disabledAccept=false;
            if (res.data.result == "true") {
              this.acceptReview = false;
              this.$notify({
                title: this.$t('lang.NText323'),
                message: res.data.message,
                duration: 3000,
                type: 'success'
              });
            }else{
              this.acceptReview = true;
              this.$notify.error({
                title: this.$t('lang.NText324'),
                message: res.data.message,
                duration: 3000
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

    },
    acceptCancel(){
        this.acceptReview = false;
    },
    zhDate_to_ehDate(e) {
      return utils.zhDate_to_enDate(e);
    },
    clickauthorize() {
      this.loading = true;
      this.listData1 = [
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
      ];
      var url = this.list.authorizeUrl.split("com")[1];
      // var url='/bms/dwBmsBookingMain/bookingDetails?id=1535868556347564032'
      var picUrl = this.getpicUrl1();
      this.$ajax.cGet(url, {}).then((res) => {
        if(res.data.result == 'true'){
          if (this.notificationType == "quotation") {
            this.loading = false;
            this.$set(this, "quotationData", JSON.stringify(res.data.data));
            this.thisRow = res.data.data ? res.data.data : {};
            setTimeout(() => {
              this.showReview = true;
            }, 20);
          } else if (this.notificationType == "booking") {
            this.$ajax
              .cPost(this.source.booking_api.bookingView, { id: picUrl })
              .then((res) => {
                this.reviewFlag = true;
                this.loading = false;
                if (res.data.result == "true") {
                  this.bookingViewData = res.data.data ? res.data.data : {};
                  if (
                    res.data.data.containerType &&
                    typeof res.data.data.containerType == "string"
                  ) {
                    this.bookingViewData.containerType = JSON.parse(
                      res.data.data.containerType
                    );
                  }
                }
              });
          }
        }else{
          this.loading = false;
          this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
          });
        }
      });
    },
    getpicUrl1() {
      // var query ='/bms/dwBmsBookingMain/bookingDetails?id=1535868556347564032'
      var query = this.list.authorizeUrl.split("com")[1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == "id") {
          return pair[1];
        }
      }
      return pair[1];
    },
    getpicUrl() {
      // var query ='/bms/dwBmsBookingMain/bookingDetails?id=1535868556347564032'
      var query = this.list.reviewUrl.split("com")[1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == "id") {
          return pair[1];
        }
      }
      return pair[1];
    },
    reviewDetail(val) {
      this.loading = true;
      this.stateView = val;
      this.disabledRP = false;
      this.listData1 = [
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
        { lInevitable: [], Adhoc: [] },
      ];
      var picUrl = this.getpicUrl();
      var url = this.list.reviewUrl.split("com")[1];
      // var url='/bms/dwBmsBookingMain/bookingDetails?id=1535868556347564032'
      this.$ajax.cGet(url, {}).then((res) => {
        if(res.data.result == 'true'){
          if (this.notificationType == "quotation") {
            this.loading = false;
            this.$set(this, "quotationData", JSON.stringify(res.data.data));
            this.thisRow = res.data.data ? res.data.data : {};
            setTimeout(() => {
              this.showReview = true;
            }, 20);
          } else if (this.notificationType == "booking") {
            this.$ajax
              .cPost(this.source.booking_api.bookingView, { id: picUrl })
              .then((res) => {
                this.reviewFlag = true;
                this.loading = false;
                if (res.data.result == "true") {
                  this.bookingViewData = res.data.data ? res.data.data : {};
                  if (
                    res.data.data.containerType &&
                    typeof res.data.data.containerType == "string"
                  ) {
                    this.bookingViewData.containerType = JSON.parse(
                      res.data.data.containerType
                    );
                  }
                }
              });
          }
        }else{
          this.loading = false;
          this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
          });
        }
      });
    },
    changeRadio(e) {
      this.searchForm.notificationStatus = e;
    },
    // 表格行内容
    tableRowClassName(obj, callback) {
      if (obj.row.notificationStatus && obj.row.notificationStatus === "1") {
        callback("danger-row");
        // return 'danger-row'
      } else {
        callback("");
        // return ''
      }
    },
    refresh() {
      this.$set(this.searchForm, "notificationType", "");
      this.$set(this.searchForm, "subject", "");
      this.$set(this.searchForm, "createDate", "");
      this.$set(this.searchForm, "sendTo", "");
      this.$set(this.searchForm, "notificationStatus", "");
      this.queryForm.notificationType = "";
      this.queryForm.subject = "";
      this.queryForm.sendTo = "";
      this.queryForm.notificationStatus = "";
      this.queryForm.startTime = "";
      this.queryForm.endTime = "";
      this.$refs.notificationList.query();
    },
    clickAccept() {
      this.acceptReview=true;
      this.form.role = [];
      this.form.senderUserAccount = this.list.senderUserAccount.slice(0,3) + '********' + this.list.senderUserAccount.slice(this.list.senderUserAccount.length - 4);
      this.form.partnerName = '';
      if (this.$refs["partnerForm"]) {
        // 延时执行
        this.$nextTick(function () {
          this.$refs["partnerForm"].clearValidate();
        });
      }
      // this.disabledAccept=true;
      // this.loading = true;
      // let url = this.list.acceptUrl.split("com")[1];
      // this.$ajax.cGet(url, {}).then((res) => {
      //   this.loading = false;
      //   if (res.data.result == "true") {
      //     this.$notify({
      //       title: this.$t('lang.NText323'),
      //       message: res.data.message,
      //       duration: 3000,
      //       type: 'success'
      //     });
      //   }else{
      //     this.$notify.error({
      //       title: this.$t('lang.NText324'),
      //       message: res.data.message,
      //       duration: 3000
      //     });
      //   }
      // });
    },
    clickReject() {
      this.disabledReject=true;
      this.loading = true;
      // window.open(this.list.rejectUrl,"_blank")
      let url = this.list.rejectUrl.split("com")[1];
      this.$ajax.cGet(url, {}).then((res) => {
        this.loading = false;
        if (res.data.result == "true") {
          this.$notify({
            title: this.$t('lang.NText323'),
            message: res.data.message,
            duration: 3000,
            type: 'success'
          });
        }else{
          this.$notify.error({
            title: this.$t('lang.NText324'),
            message: res.data.message,
            duration: 3000
          });
        }
      });
    },
    // clickquotation() {
    //   let url = this.list.quotationLinkage.split("com")[1];
    //   this.$ajax.cGet(url, {}).then((res) => {
    //     if (res.data.result == "true") {
    //       this.quotationData = res.data.data;
    //       console.log(this.quotationData);
    //       this.detailFlag = false;
    //       this.showReview = true;
    //     }
    //   });
    // },
    // clickConfirm() {
    //   // window.open(this.list.confirmUrl,"_blank")
    //   let url = this.list.confirmUrl.split("com")[1];
    //   this.$ajax.cGet(url, {}).then((res) => {
    //     console.log(res);
    //   });
    // },
    query() {
      this.queryForm.notificationType = this.searchForm.notificationType;
      this.queryForm.subject = this.searchForm.subject;
      this.queryForm.sendTo = this.searchForm.sendTo;
      this.queryForm.notificationStatus = this.searchForm.notificationStatus;
      this.queryForm.startTime = this.searchForm.createDate
        ? this.searchForm.createDate[0].split("T")[0]
        : "";
      this.queryForm.endTime = this.searchForm.createDate
        ? this.searchForm.createDate[1].split("T")[0]
        : "";
      this.$refs.notificationList.query();
    },
    clickProcess(data) {
      this.detailFlag = true;
      if (data.Type == "B") {
        this.type = "3";
        this.stepsData = [
          {
            time: "Dec 7th,2021 2:32 pm",
            user: "Grey Chen",
            content: "Submit Booking, BID: XXXXXXXX",
          },
          {
            time: "Dec 7th,2021 5:30 pm",
            user: "Terry",
            content: "Created pricing, QID: XXXXXXXX",
          },
          {
            time: "Dec 8th,2021 10:12 am",
            user: "Grey Chen",
            content: "Pricing confirmed",
          },
          {
            time: "Dec 8th,2021 10:22 am",
            user: "Grey Chen",
            content: "Submit Booking, BID: XXXXXXXX",
          },
          {
            time: "Dec 7th,2021 5:30 pm",
            user: "Terry",
            content: "Submit Booking, BID: XXXXXXXX",
          },
        ];
      } else {
        this.type = "2";
        this.stepsData = [
          {
            time: "Dec 7th,2021 2:32 pm",
            user: "Grey Chen",
            content: "Submit Booking, BID: XXXXXXXX",
          },
          {
            time: "Dec 7th,2021 5:30 pm",
            user: "Terry",
            content: "Created pricing, QID: XXXXXXXX",
          },
          {
            time: "",
            user: "",
            content: "Pricing confirmed",
          },
          {
            time: "",
            user: "",
            content: "Submit Booking, BID: XXXXXXXX",
          },
          {
            time: "",
            user: "",
            content: "Submit Booking, BID: XXXXXXXX",
          },
        ];
      }
    },
    createBooking() {
      this.type = 0;
      this.dialogTitle = this.$t("lang.BText34");
      this.detailFlag = true;
      this.review = false;
    },
    clickType(type) {
      console.log(type);
      this.type = type;
    },
    // notificationNum(){
    //   let that = this;
    //   that.$ajax
    //     .cPost(that.source.notification_api.unReadNumber,{})
    //     .then((res) => {
    //       if (res.data.result=='true') {
    //         sessionStorage.setItem('unReadNumberNum',res.data.list.unReadNumber)
    //         // that.num = sessionStorage.getItem('unReadNumberNum');
    //       } else {
    //         sessionStorage.setItem('unReadNumberNum',0)
    //         // that.num=sessionStorage.getItem('unReadNumberNum');
    //       }
    //     });

    // },
    clickView(data) {
      this.notificationType = data.notificationType;
      this.detailFlag = true;
      this.loading = true;
      this.list = {};
      this.ruleType = data.ruleType;
      let data1 = {
        id: data.id,
      };
      this.disabledAccept=false;
      this.disabledReject=false;
      data.notificationStatus = "1";
      this.$ajax
        .cPost(this.source.notification_api.details, data1)
        .then((res) => {
          if (res.data.result == "true") {
            this.loading = false;
            this.list = res.data.list.notificationDetails;
            console.log(this.list);
            this.$emit("Fun");
            // this.notificationNum()
          }
        });
      // if(data.ruleType=='004'){
      //   let data1 = {
      //     id: data.id,
      //   };
      //   this.$ajax
      //     .cPost(this.source.notification_api.details, data1)
      //     .then((res) => {
      //       if (res.data.result == "true") {
      //         this.detailFlag=true
      //         this.list=res.data.list.notificationDetails
      //       }
      //     });
      // }else if(data.ruleType=='005'){
      //   let data1 = {
      //     id: data.id,
      //   };
      //   this.$ajax
      //     .cPost(this.source.notification_api.details, data1)
      //     .then((res) => {
      //       if (res.data.result == "true") {
      //         this.detailFlag=true
      //         this.list=res.data.list.notificationDetails
      //       }
      //     });
      // }else if(data.ruleType=='002'){
      //   let data1 = {
      //     id: data.id,
      //   };
      //   this.$ajax
      //     .cPost(this.source.notification_api.details, data1)
      //     .then((res) => {
      //       if (res.data.result == "true") {
      //         this.detailFlag=true
      //         this.list=res.data.list.notificationDetails
      //       }
      //     });
      // }else if(data.ruleType=='001'){
      //   let data1 = {
      //     id: data.id,
      //   };
      //   this.$ajax
      //     .cPost(this.source.notification_api.details, data1)
      //     .then((res) => {
      //       if (res.data.result == "true") {
      //         this.detailFlag=true
      //         this.list=res.data.list.notificationDetails
      //       }
      //     });
      // }
    },
    clickReview() {
      setTimeout(() => {
        this.toTop();
      }, 10);
      this.type = null;
      this.review = true;
    },
    toTop() {
      var element = document.getElementById("bookingDialog");
      element.scrollIntoView();
    },
    closeDialog() {
      this.detailFlag = false;
    },
    closeDialog1() {
      this.reviewFlag = false;
    },
    openCheckBox() {
      this.reviewFlag = false;
    },
    clickEdit(data) {
      if (data && data.bookingId) {
        this.bookingId = data.bookingId;
      }
      this.type = null;
      this.dialogTitle = this.$t("lang.SText141");
      this.detailFlag = true;
      this.edit = true;
      this.review = false;
    },
    clickDraft() {
      this.$router.push({ path: "/Booking/Draft" });
    },
    clickTemplate() {
      this.$router.push({ path: "/Booking/Template" });
    },
    onCopy() {
      this.$notify({
        title: this.$t("lang.SText125"),
        // message: "这是一条成功的提示消息",
        type: "success",
        offset: 100,
      });
      // this.$notify({
      //   dangerouslyUseHTMLString: true,
      //   message: '<svg-icon icon-class="check" style="height:40px;width:40px"></svg-icon><div class="text" style="color:#808080;font-size:20px">Copy successfully</div>',
      // });
    },
    onError() {},
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.Notification {
  padding-bottom: 30px;

  .el-dialog {
    margin-top: 0 !important;
    min-height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      min-height: 100%;
      .editInfo {
        width: 100%;
        margin-top: 30px;
        padding: 0 255px;
        box-sizing: border-box;
        float: left;
        text-align: center;
        .editInfoTitle{
          text-align: left;
          color: #132569;
          font-size: 27px;
          border-bottom: 2px solid #132569;
          line-height: 45px;
          margin: 0;
          font-weight: 600;
          span{
            color: #299be4;
          }
        }
        .firstLine{
          text-align: left;
          color: #132569;
          font-size: 18px;
          margin: 15px 0 100px 0;
          span{
            color: #299be4;
          }
        }
        .el-input__inner {
          height: 48px;
        }
        .el-checkbox__label{
          line-height: 36px;
          color: #132569;
        }
        .el-form{
          width: 60%;
          margin-left: 20%;
        }
        .el-form-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 35px;
          .el-form-item__label{
            padding: 0;
          }
          .el-form-item__content{
            text-align: left;
          }
        }
        .bottom-button {
          margin: auto;
          width: 400px;
          margin-bottom: 25px;
          .el-button {
            width: 180px;
            height: 64px;
          }
        }
      }
    }
  }
  span.code {
    margin-bottom: 38px;
    width: auto !important;
    border: 2px solid #132569;
    padding: 10px 25px;
    font-weight: 600;
  }
  .mt20 {
    margin-bottom: 20px;
  }
  .col299 {
    color: #299be4 !important;
  }
  .mt6 {
    margin-bottom: 6px;
  }
  .btn {
    text-decoration: underline;
    color: #eb4b4b;
    font-size: 18px;
    line-height: 20px;
  }
  .mt25 {
    margin-bottom: 25px;
  }
  .mt30 {
    margin-bottom: 30px;
  }
  .fw600 {
    font-weight: 600;
  }
  .fs38 {
    font-size: 38px;
  }
  .mt38 {
    margin-bottom: 38px;
  }
  .el-dialog {
    margin-top: 0 !important;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }

    .dialog-header {
      height: 105px;
      padding: 20px;
      background-color: $mainColor;
      display: flex;
      .dw-logo {
        width: 70px;
        height: 70px;
        padding: 15px;
        .svg-icon {
          width: 70px;
          height: 70px;
          color: #fff;
        }
      }
      .close {
        width: 50px;
        height: 50px;
        margin-top: 40px;
        margin-left: 100px;
        cursor: pointer;
        .close-icon {
          width: 50px;
          height: 50px;
          fill: #fff;
          stroke: #fff;
        }
      }
      .dialog-title {
        color: #fff;
        margin-left: 90px;
        text-align: left;
        width: 600px;

        .row1 {
          margin-top: 5px;
        }
        .row2 {
          margin-top: 14px;
          color: $fontColor1;
        }
      }
      .titleDate {
        display: flex;
        border-bottom: 2px solid #fff;
        padding-top: 20px;
        text-align: left;
        height: 60px;
        .updateDate {
          .date {
            font-size: 20px;
            line-height: 30px;
            color: #fff;
            margin-right: 20px;
          }
          .date2 {
            font-size: 20px;
            line-height: 30px;
            color: $background2;
          }
        }
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 30px;
      background-color: $mainColor;
      color: #fff;
      padding: 4px;
    }
  }
  .el-table {
    .qua {
      width: 30px;
      height: 30px;
      border: 1px solid #132569;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
    }
    .danger-row {
      color: #999999;
      .qua {
        border: 1px solid #999999;
      }
    }
  }
  .acceptDialog {
    .dialog-main-content {
      width: 50%;
      margin: 100px auto;
    }
  }
  .notificationDialog {
    .dialog-main-content {
      padding: 100px;
      float: left;
      span,
      a {
        float: left;
        width: 100%;
        text-align: left;
        line-height: 30px;
        color: $mainColor;
        font-size: 20px;
      }
      .el-button {
        float: left;
        background: $mainColor;
        padding: 0 35px;
        margin-bottom: 42px;
        height: 45px;
        line-height: 45px;
        box-sizing: border-box;
        border-radius: 0;
        span {
          color: #fff;
        }
      }
      .el-button.sec {
        background: #fff;
        border: 1px solid $mainColor;
        span {
          color: $mainColor;
        }
      }
      .SubjectTime {
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        color: $mainColor;
        font-size: 20px;
        border-bottom: 2px solid $mainColor;
        p {
          margin: 0;
        }
      }
    }
    .padding {
      padding: 30px 60px;
    }
  }
  .gantan {
    color: $background3;
    font-size: 20px;
  }
  .NotificationType {
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 20px;
    border: 2px solid $mainColor;
    color: $mainColor;
  }
  .Notification-title {
    background-color: $mainColor;
    height: 150px;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    color: #fff;

    .left {
      line-height: 150px;
      padding-left: 50px;
    }

    .svg-icon {
      margin-right: 20px;
    }

    .close {
      width: 50px;
      height: 50px;
      margin: 50px;
      cursor: pointer;
      .close-icon {
        width: 50px;
        height: 50px;
        color: #fff;
      }
    }
  }
  .LinkID {
    display: flex;
    .text {
      text-decoration: underline;
    }
    .svg-icon {
      color: $borderColor1;
      margin-left: 10px;
    }
  }
  .BOID {
    color: $background2;
  }

  .createDialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__close {
      display: none;
    }

    .TaskLog-title {
      text-align: left;
      // border-bottom: 2px solid $mainColor;
      width: 1120px;
      margin: auto;
      display: flex;
      padding: 30px 0 10px 0;
      font-size: 30px;
      .TaskLog-title-ID {
        padding-left: 20px;
        color: $mainColor;
        line-height: 45px;
      }
      .svg-icon {
        font-size: 20px;
        color: $fontColor1;
        padding-left: 30px;
      }
    }

  }

  .list-header {
    display: flex;
    justify-content: space-between;
    z-index: 9;
    .header-left {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $mainColor;
      margin-bottom: 20px;
      padding-bottom: 10px;
      // height: 100px;
      overflow: hidden;
      .form {
        display: flex;
        flex-wrap: wrap;

        .search-item {
          margin-right: 20px;
          margin-top: 15px;
          .form-item-title {
            text-align: left;
            color: $fontColor2;
            line-height: 40px;
          }
          .el-input__inner {
            height: 48px;
            line-height: 48px;
          }
          .radio {
            padding-top: 50px;
          }
          .el-date-editor {
            width: 360px !important;
          }
          .el-range-separator {
            line-height: 40px;
          }
        }
      }

      .search-button {
        display: flex;
        margin-top: 50px;
        .refresh-button {
          height: 44px;
          width: 44px;
          border: 2px solid $mainColor;
          margin-right: 10px;
          .svg-icon {
            height: 20px;
            width: 20px;
            color: $mainColor;
            fill: $mainColor;
            stroke: $mainColor;
            margin-top: 12px;
          }
        }
        .refresh-button:hover {
          border: 2px solid $mainColor;
          background-color: $background2;
        }
        .searchbutton {
          height: 48px;
          background-color: $mainColor;
          color: #fff;
          border-radius: 0;
          font-family: "montserratregular";
          font-size: 18px;
          border: 2px solid $mainColor;
        }
        .searchbutton:hover {
          border: 2px solid $mainColor;
          background-color: $background2;
        }
        .icon {
          height: 20px;
          width: 20px;
          color: $mainColor;
          padding: 12px;
          margin: 0 10px;
        }

        .icon:hover {
          height: 26px;
          width: 26px;
          padding: 9px;
        }
      }
    }

    .header-right {
      margin-top: 50px;
      .el-button {
        height: 60px;
        font-size: 18px;
        color: $mainColor;
        border: 2px solid $mainColor;
        border-radius: 0;
        font-family: "montserratregular";
      }
      .el-button:hover {
        background-color: $background2;
        color: #fff;
        border: 2px solid $mainColor;
      }
      .create {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .router-slid-enter-active,
  .router-slid-leave-active {
    transition: all 0.5s;
  }
  .router-slid-enter,
  .router-slid-leave-to {
    transform: scaleY(0);
    transform-origin: 0 1%;
    opacity: 0;
  }
}
</style>
